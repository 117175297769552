<template>
  <div>
    <section class="main-content templates-container">
      <div class="grid-x grid-margin-x">
        <div class="cell large-6 select-table">
          <div class="grid-x">
            <div class="search-container">
              <input
                type="text"
                v-model="search"
                @keyup.enter="submit"
                placeholder="Buscar..."
              >
            </div>
          </div>
          <data-table-assign-users
            :headers="headers"
            :items="agents"
            :check="true"
            :paginationOptions="paginationOptions"
            :loader="$apollo.queries.usersByCampaign.loading"
            :delete-check="deleteCheck"
            v-on:change-page="changePage"
          />
        </div>
      </div>
      <div class="row">
        <div class="secondary-actions">
          <div class="back-button">
            <button
              class="button outline lg"
              @click="prev"
            >
              {{ $t('templates.prev') }}
            </button>
          </div>
          <div class="send-button">
            <button
              class="button primary lg"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import DataTableAssignUsers from '@/components/settings/DataTableAssignUsers.vue';
import UsersByCampaignGql from '@/graphql/queries/users/usersByCampaign.gql';
import executeQuery from '@/utils/gql-api';

export default {
  components: {
    DataTableAssignUsers,
  },
  data() {
    return {
      search: null,
      headers: [
        {
          text: 'Nombre',
          value: 'name',
        },
        {
          text: 'Correo',
          value: 'email',
        },
        {
          text: 'Canales',
          value: 'channels',
        },
      ],
      paginationOptions: {
        pageTotal: 0,
        page: 1,
        perPage: 10,
      },
      usersByCampaign: [],
      deleteCheck: false,
    };
  },
  apollo: {
    usersByCampaign() {
      return {
        query: gql`${UsersByCampaignGql}`,
        variables() {
          return {
            id: this.campaignId,
            length: this.paginationOptions.perPage,
            all: true,
            search: null,
            page: 1,
            role: 'agent',
          };
        },
        update: (data) => {
          if (data.usersByCampaign && data.usersByCampaign.total) {
            this.paginationOptions.pageTotal = data.usersByCampaign.total;
          }
          return data.usersByCampaign;
        },
        error(err) {
          this.networkErr(err);
          this.$toasted.global.error(this.$t('alerts.unexpectedError'));
        },
      };
    },
  },
  computed: {
    campaignId() {
      return this.$route.params.campaign_id;
    },
    agents() {
      if (this.usersByCampaign && this.usersByCampaign.data) {
        return this.usersByCampaign.data.map((agent) => {
          const data = { ...agent };
          data.channels = [];
          agent.campaigns.forEach((campaign) => {
            data.channels = [...data.channels, ...campaign.channels];
          });
          return data;
        });
      }
      return [];
    },
  },
  methods: {
    changePage(page) {
      if (this.paginationOptions.page !== page) {
        this.paginationOptions.page = page;
        this.fetchMore(this.search, this.paginationOptions.page);
      }
    },
    prev() {
      this.reset();
      this.$router.push(`/campaigns/${this.campaignId}/settings`);
    },
    reset() {
      this.deleteCheck = false;
      this.search = null;
    },
    async fetchMore(search, page) {
      this.usersByCampaign = await executeQuery('usersByCampaign',
        UsersByCampaignGql,
        {
          id: this.campaignId,
          length: this.paginationOptions.perPage,
          all: true,
          search,
          page,
        });
    },
    submit() {
      this.fetchMore(this.search, 1);
    },
    deleteSelected() {
      this.deleteCheck = true;
      setTimeout(() => {
        this.deleteCheck = false;
      }, 500);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~styles/views/_campaigns-assign.users.scss';
</style>
