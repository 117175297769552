<template>
  <nav aria-label="Pagination">
    <ul
      v-if="pager.pages && pager.pages.length"
      class="pagination"
      :style="ulStyles"
    >
      <li
        class="pagination-first"
        :style="liStyles"
      >
        <button
          class="page-link"
          :class="{'disabled': pager.currentPage === 1}"
          :disabled="pager.currentPage === 1"
          @click="setPage(1)"
          :style="aStyles">{{labels.first}}
        </button>
      </li>
      <li
        class="pagination-previous"
        :style="liStyles"
      >
        <button
          class="page-link"
          :class="{'disabled': pager.currentPage === 1}"
          :disabled="pager.currentPage === 1"
          @click="setPage(pager.currentPage - 1)"
          :style="aStyles">{{labels.previous}}
        </button>
      </li>
      <li
        v-for="page in pager.pages"
        :key="page" class="page-item page-number"
        :class="{'current': pager.currentPage === page}"
        :style="liStyles"
      >
        <button class="page-link" @click="setPage(page)" :style="aStyles">{{page}}</button>
      </li>
      <li
        class="pagination-next"
        :style="liStyles"
      >
        <button
          class="page-link"
          :class="{'disabled': pager.currentPage === pager.totalPages}"
          :disabled="pager.currentPage === pager.totalPages"
          @click="setPage(pager.currentPage + 1)"
          :style="aStyles"
        >
          {{labels.next}}
        </button>
      </li>
      <li
        class="pagination-last"
        :style="liStyles"
      >
        <button
          class="page-link"
          :class="{'disabled': pager.currentPage === pager.totalPages}"
          :disabled="pager.currentPage === pager.totalPages"
          @click="setPage(pager.totalPages)"
          :style="aStyles"
        >
          {{labels.last}}
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
import paginate from 'jw-paginate';

const defaultStyles = {};
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    initialPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    maxPages: {
      type: Number,
      default: 10,
    },
    styles: {
      type: Object,
    },
    disableDefaultStyles: {
      type: Boolean,
      default: false,
    },
    totalPages: {
      type: Number,
      default: 1,
      required: false,
    },
  },
  data() {
    return {
      labels: {
        first: this.$t('pagination.first'),
        last: this.$t('pagination.last'),
        previous: this.$t('pagination.previous'),
        next: this.$t('pagination.next'),
      },
      pageCurrent: 1,
      pager: {},
      ulStyles: {},
      liStyles: {},
      aStyles: {},
    };
  },
  created() {
    if (!this.$listeners.changePage) {
      // eslint-disable-next-line no-throw-literal
      throw 'Missing required event listener: "changePage"';
    }
    // set default styles unless disabled
    if (!this.disableDefaultStyles) {
      this.ulStyles = defaultStyles.ul;
      this.liStyles = defaultStyles.li;
      this.aStyles = defaultStyles.a;
    }
    // merge custom styles with default styles
    if (this.styles) {
      this.ulStyles = { ...this.ulStyles, ...this.styles.ul };
      this.liStyles = { ...this.liStyles, ...this.styles.li };
      this.aStyles = { ...this.aStyles, ...this.styles.a };
    }
    // set to initial page
    this.setPage(this.initialPage);
  },
  methods: {
    setPage(page) {
      const { totalPages, pageSize, maxPages } = this;
      // get new pager object for specified page
      const pager = paginate(totalPages, page, pageSize, maxPages);
      // get new page of items from items array
      // const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);
      // update pager
      this.pager = pager;
      // emit change page event to parent component
      this.pageCurrent = page;
      this.$emit('changePage', this.pageCurrent);
    },
  },
  watch: {
    items() {
      this.setPage(this.pageCurrent);
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/_pagination.scss';
</style>
